/*
  To show descriptions on an element by mouseover event
  visit http://qtip2.com/options#position for API positioning and examples
*/

const light = {
  classes: "qtip-light qtip-shadow"
}
const dark = {
  classes: "qtip-dark qtip-shadow qtip-rounded"
}

// Defaults for all tooltips
const defaults = {
  show: {
    delay: 500,
    event: 'mouseover',
    ready: true
  },
  hide: {
    fixed: true,
    delay: 100,
    event: 'mouseleave'
  },
  overwrite: false,
  style: light,
  position: {
    effect: false,
    viewport: true
  }
}

// Tooltip definitions
const tooltips = [

  // Alert tooltip
  {
    target: ".notif.not-read .read-toggle",
    content: `Mark as read`,
    overrides: {
      position: {
        my: "bottom center",
        at: "center"
      },
      style: dark
    }
  },

  // Question icon tooltips
  {
    target: ".victim-case-category-desc",
    content: `<p>Offense codes are assigned to each category. The category determines the message template that is used for each offense code.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".roles-desc",
    content: `<p>Roles define the various individuals involved on an event. Each role determines if and what message the recipient will receive.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".case-event-type-category-desc",
    content: `<p>Case event types are assigned to each category. The category determines the message template that is used for each case event type.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".question-hover",
    content: `<p>Select an existing person or create a new person to associate with this business. Person will receive all messages regarding this business.</p>`,
    overrides: {
      posititon: {
        my: "left top",
        at: "top right"
      }
    }
  },

  // Sent status icons
  {
    target: ".full-circle",
    content: "<span>Sent as intended.</span>",
    overrides: {
      position: {
        my: "bottom center",
        at: "top center"
      },
      style: dark
    }
  },
  {
    target: ".half-circle",
    content: "<span>A delivery mode failed.</span>",
    overrides: {
      position: {
        my: "bottom center",
        at: "top center"
      },
      style: dark
    }
  },

  // CAD Events
  {
    target: ".cad-template-desc",
    content: `<p>Template will be used for CAD Autoresponder cases. Edit and save template here.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".cad-categories-desc",
    content: `<p>Enabling and disabling categories will determine if a message for that category will be sent.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".cad-list-links-desc",
    content: `<p>Upload CSV, Download CSV, Export Templates only seen by SPIDR Tech admins.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".cad-spidr-admins-desc",
    content: `<p>Only seen by SPIDR Tech employees.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-cad-settings__delay-desc",
    content: `<p>Delay time refers to the amount of time before Engage sends the automated reply.</p>`,
    overrides: {
      style: {
        classes: "qtip-light qtip-shadow c-cad-settings__delay-desc--tooltip",
      },
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-cad-settings__safeword-desc",
    content: `<p>The safeword is used when the reporting officer does not want an automated CAD Autoresponder SMS to be sent.
      The safeword can be added into the comments section of a CAD event.
      Engage will then recognize it, and not send an automated message for that CAD event.
      Commas can be used to separate multiple safewords.</p>`,
    overrides: {
      style: {
        classes: "qtip-light qtip-shadow c-cad-settings__safeword-desc--tooltip",
      },
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: '.js-cad-category-external-id',
    content: `<p>Category refers to the call type and description and is not editable.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: '.js-cad-category-name',
    content: `<p>Name refers to the call type category that the person will see in the text message.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },

  // Disabled contact search button
  {
    target: '.js-message-status-timestamp',
    content: `<p>The time when the message status was last updated.</p>`,
    overrides: {
      position: {
        my: "top center",
        at: "bottom center"
      }
    }
  },

  // Data table entity tooltip
  {
    target: ".data-table TD.entity_name-column A, .data-table TD.point_of_contact-column A",
    overrides: {
      position: {
        my: "top center",
        at: "bottom center"
      },
      style: {
        classes: "qtip-light qtip-shadow c-entity-preview",
      },
      events: {
        // Hide tooltip on esc keypress
        render: function (event, api) {
          $(window).bind('keyup', function (e) {
            if (e.keyCode === 27) {
              api.hide(e)
            }
          })
        }
      },
      content: {
        button: false,
        text: function (event, api) {

          // Get the html data
          $.get(
            $(this).attr("href") + "/preview"
          ).then((res) => {

            const $template = $(res)

            // Set the content to the qtip
            api.set('content.text', $template)

            // Hide the qtip when modal is opened
            $template.on("click", ".open-modal", (e) => {
              api.hide()
            })
          })

          return 'Loading...'

        }
      }
    }
  },

  // Admin - System Settings
  {
    target: ".admin-panel-users__admin-tooltip",
    content: `<p>Default users have limited access to basic features.</br></br> Exporters can export Spotlight data.</br></br> Admins have full access, including user management and settings.</p>`,
    overrides: {
      position: {
        my: "bottom center",
        at: "top center"
      }
    },
  },

  // New Admin Settings
  {
    target: ".c-new-admin-settings__followup-survey-tooltip",
    content: `<p>This setting determines which survey to send as a follow-up.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__survey-on-call-closed-tooltip",
    content: `<p>If this is enabled, we will send the CAD Responder survey when our system receives the call closed timestamp (or equivalent).</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__call-closed-survey-delivery-delay-tooltip",
    content: `<p>We will wait this amount of time from call closure, if call closure is selected above as the trigger, to send the CAD Responder Survey.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__safewords-tooltip",
    content: `<p>SPIDR Tech will not send messages if we detect this word in the call notes (if call notes are sent to SPIDR Tech).</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__delayed-arrival-tooltip",
    content: `<p>This is the amount of time that elapses between when SPIDR Tech receives the data and sends the message.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__survey-reminder-time-tooltip",
    content: `<p>If a recipient has not yet taken a survey, SPIDR Tech will send a survey reminder message after this amount of time elapses.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__category-enabled-tooltip",
    content: `<p>Enabling and disabling categories will determine if a message for that category will be sent.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__delivery-delay-tooltip",
    content: `<p>This is the amount of time that elapses between when SPIDR Tech receives the data and sends the message.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__use-do-not-disturb-tooltip",
    content: `<p>This setting will stop messages from being sent during a specified time period.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__delayed-entry-cutoff-tooltip",
    content: `<p>This setting will tell the system when an incident is too far in the past to send a message.</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  {
    target: ".c-new-admin-settings__interval-tooltip",
    content: `<p>Intervals must be simple to parse. For example "1 day". If you need to combine multiple parts, please structure your input from the longest duration to the shortest. For example, "1 week 3 days".</p>`,
    overrides: {
      position: {
        my: "left top",
        at: "center right"
      }
    }
  },
  // Admin - Template Nav
  {
    target: ".browser-mode-tooltip",
    content: `<p>If you include variable {{Web: Hyperlink}} in the SMS template, the Browser template will be displayed when the recipient opens the link.</p>`,
    overrides: {
      position: {
        my: "right top",
        at: "center left"
      }
    }
  }
]


// Initialize the tooltips
tooltips.forEach(function (tooltip) {

  $(document).on("mouseover", tooltip.target, (event) => {

    $(event.currentTarget).qtip(_.merge(
      {},
      defaults,
      {
        content: {
          text: (e, api) => tooltip.content
        }
      },
      tooltip.overrides
    ))

  })

})
